import { MailOutlined, FolderOutlined, PeopleOutline, CheckSharp } from '@mui/icons-material'
import { PortalDomainOrg, PortalOrder } from '../../types'
import { NotificationsInfo } from '../../utils'
import { SectionIcon } from './Section'
import { WiringInstructionsIcon } from './WiringInstructionsSection'

export type SectionConfig = {
  type: SectionName;
  icon: JSX.Element;
  name: string;
  isStandaloneSection: boolean
  secondaryText: string;
  routeTo: null | string,
  searchParams: any;
  hideInClosingPage?: boolean;
  sortOrder?: number;
}

// Wonder why this isn't working:
// export type SectionName = keyof typeof sectionMap
export type SectionName = (
  | 'notifications'
  | 'closing-documents'
  | 'wiring-instructions'
  | 'closing-contacts'
  | 'tasks'
)

export const sectionMap: {[key: string]: SectionConfig} = {
  'notifications': {
    type: 'notifications',
    icon: <SectionIcon icon={<MailOutlined />} />,
    name: 'Messages',
    isStandaloneSection: true,
    secondaryText: '',
    routeTo: null,
    searchParams: { component: 'messages' },
  },
  'closing-documents': {
    type: 'closing-documents',
    icon: <SectionIcon icon={<FolderOutlined />} />,
    name: 'Documents',
    isStandaloneSection: false,
    secondaryText: '',
    routeTo: null,
    searchParams: {},
  },
  'wiring-instructions': {
    type: 'wiring-instructions',
    icon: <SectionIcon icon={<WiringInstructionsIcon />} />,
    name: 'Wire Transfer Instructions',
    isStandaloneSection: false,
    secondaryText: '',
    routeTo: null,
    searchParams: {},
  },
  'closing-contacts': {
    type: 'closing-contacts',
    icon: <SectionIcon icon={<PeopleOutline />} />,
    name: 'Contacts',
    isStandaloneSection: false,
    secondaryText: '',
    routeTo: null,
    searchParams: {},
  },
  'tasks': {
    type: 'tasks',
    icon: <SectionIcon icon={<CheckSharp />} />,
    name: 'Tasks',
    isStandaloneSection: false,
    secondaryText: '',
    routeTo: 'notifications',
    searchParams: {},
  },
}

/**
 * Returns an array of sections to render, based on the presence of data like
 * notifications and UiConfig.  Note the tracker and closing details are
 * currently handled special by the ui so are returned separately.
 */
export function getSectionsConfig({ notificationsInfo, order, org }: {
  notificationsInfo: NotificationsInfo;
  order: PortalOrder;
  org: PortalDomainOrg;
}) {
  let section: any
  let sectionsConfig: SectionConfig[] = []

  if (notificationsInfo.hasTasks) {
    section = org.UiConfig?.Sections?.Tasks

    if (section?.Enabled !== false) {
      sectionsConfig.push({
        ...sectionMap['tasks'],
        secondaryText: notificationsInfo.tasksCountText,
        name: section?.Title || sectionMap['tasks'].name,
        sortOrder: section?.SortOrder,
      })
    }
  }

  if (notificationsInfo.hasMessages) {
    section = org.UiConfig?.Sections?.Messages

    if (section?.Enabled !== false) {
      sectionsConfig.push({
        ...sectionMap['notifications'],
        secondaryText: notificationsInfo.messagesCountText,
        name: section?.Title || sectionMap['notifications'].name,
        sortOrder: section?.SortOrder,
      })
    }
  }

  // HACK - if we have tasks but not messages, add in a notifications section
  // which renders the notifications page as a child.  We
  // mark it to hide its summary view.
  if (sectionsConfig.find(c => c.type === 'tasks') && !sectionsConfig.find(c => c.type === 'notifications')) {
    sectionsConfig.push({
      ...sectionMap['notifications'],
      hideInClosingPage: true,
      sortOrder: section?.SortOrder,
    })
  }

  section = org.UiConfig?.Sections?.ClosingDocuments
  if (section?.Enabled !== false) {
    sectionsConfig.push({
      ...sectionMap['closing-documents'],
      name: section?.Title || sectionMap['closing-documents'].name,
      sortOrder: section?.SortOrder,
    })
  }

  if (order.WiringInstructions) {
    section = org.UiConfig?.Sections?.WiringInstructions

    if (section?.Enabled !== false) {
      sectionsConfig.push({
        ...sectionMap["wiring-instructions"],
        secondaryText: notificationsInfo.wiringInstructionsTaskText,
        name: section?.Title || sectionMap["wiring-instructions"].name,
        sortOrder: section?.SortOrder,
      });
    }
  }

  if (order.ClosingContacts) {
    section = org.UiConfig?.Sections?.ClosingContacts

    if (section?.Enabled !== false) {
      sectionsConfig.push({
        ...sectionMap['closing-contacts'],
        name: section?.Title || sectionMap['closing-contacts'].name,
        sortOrder: section?.SortOrder,
      })
    }
  }

  let closingTrackerSectionConfig = null
  section = org.UiConfig?.Sections?.Tracker
  if (section?.Enabled !== false) {
    closingTrackerSectionConfig = {
      name: section?.Title || 'Status Tracker'
    }
  }

  let closingDetailsSectionConfig = null
  section = org.UiConfig?.Sections?.ClosingDetails
  if (section?.Enabled !== false) {
    closingDetailsSectionConfig = {
      name: section?.Title || 'Signing Details',
    }
  }
  
  if (sectionsConfig.every(section => section.sortOrder !== undefined && section.sortOrder !== null)) {
    sectionsConfig.sort((a, b) => (a.sortOrder ?? 0) - (b.sortOrder ?? 0));
  }

  return { sectionsConfig, closingTrackerSectionConfig, closingDetailsSectionConfig  }
}
